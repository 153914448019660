<template>
    <section class="educational-partnership">
        <Nav
            class="mt-5 mb-4"
            route-name="Partnership"
            parent-page-name="Партнерство"
            current-page-name="Образовательное партнерство"
        />

        <div class="educational-partnership__main-img d-none d-xl-flex">
            <div class="educational-partnership__blurred-img mt-auto ms-auto d-flex flex-column">
                <h2 class="educational-partnership__title">{{ getEducationalPartnershipPageInfo('title') }}</h2>
                <div class="educational-partnership__text mt-3">
                    {{ getEducationalPartnershipPageInfo('text') }}
                </div>
                <img
                    src="../../assets/page-logo.svg"
                    class="innovative-partnership__logo ms-auto mt-lg-auto d-none d-xl-block"
                    alt=""
                    width="259"
                    height="72"
                >
            </div>
        </div>

        <div class="title-wrapper d-xl-none my-4 my-lg-5">
            <h2 class="educational-partnership__title educational-partnership__title--dark">
                {{ getEducationalPartnershipPageInfo('title') }}
            </h2>
            <div class="educational-partnership__text educational-partnership__text--dark">
                {{ getEducationalPartnershipPageInfo('text') }}
            </div>
        </div>

        <div class="d-flex flex-column align-items-center flex-md-row align-items-md-start">
            <div class="statistic-card d-flex flex-column col-4 align-items-center">
                <div class="statistic-card__number">
                    {{ getStatNumber(1) }}
                </div>

                <div class="text-center statistic-card__text mt-4">
                    {{ getStatDescription(1) }}
                </div>
            </div>

            <div class="statistic-card d-flex flex-column col-4 align-items-center mt-4 mt-md-0">
                <div class="statistic-card__number">{{ getStatNumber(2) }}</div>

                <div class="text-center statistic-card__text mt-4">
                    {{ getStatDescription(2) }}
                </div>
            </div>

            <div class="statistic-card d-flex flex-column col-4 align-items-center mt-4 mt-md-0">
                <div class="statistic-card__number">{{ getStatNumber(3) }}</div>

                <div class="text-center statistic-card__text mt-4">
                    {{ getStatDescription(3) }}
                </div>
            </div>
        </div>

        <div
            class="d-flex flex-column align-items-center flex-xxl-row align-items-xxl-start about-block">
            <div class="educational-partnership__scheme col-12 col-xxl-6 ms-xxl-5">
                <div class="position-relative pt-5 mt-5">
                    <!-- Number 3 -->
                    <img
                        class="number1 position-absolute"
                        src="../../assets/views/educational_partnership/1.png"
                        alt=""
                        width="63px"
                        height="63px"
                    >
                    <img
                        class="number1__line position-absolute"
                        src="../../assets/views/educational_partnership/straight-line1.svg"
                        alt=""
                    >
                    <div class="position-absolute number1__text number-text">
                        {{ getStageDescription(1)  }}
                    </div>

                    <!-- Number 2 -->
                    <img
                        class="number2 position-absolute"
                        src="../../assets/views/educational_partnership/2.png"
                        alt=""
                        width="63px"
                        height="63px"
                    >
                    <img
                        class="number2__line position-absolute"
                        src="../../assets/views/educational_partnership/straight-line2.svg"
                        alt=""
                    >
                    <div class="number2__text number-text position-absolute">
                        {{ getStageDescription(2) }}
                    </div>

                    <!-- Number 3 -->
                    <img
                        class="number3 position-absolute"
                        src="../../assets/views/educational_partnership/3.png"
                        alt=""
                        width="63px"
                        height="63px"
                    >
                    <img
                        class="number3__line position-absolute"
                        src="../../assets/views/educational_partnership/straight-line3.svg"
                        alt=""
                    >
                    <div class="number3__text number-text position-absolute">
                        {{ getStageDescription(3) }}
                    </div>

                    <!-- Number 4 -->
                    <img
                        class="number4 position-absolute"
                        src="../../assets/views/educational_partnership/4.png"
                        alt=""
                        width="63px"
                        height="63px"
                    >
                    <img
                        class="number4__line position-absolute"
                        src="../../assets/views/educational_partnership/straight-line4.svg"
                        alt=""
                    >
                    <div class="number4__text number-text position-absolute">
                        {{ getStageDescription(4) }}
                    </div>

                    <!-- Number final -->
                    <img
                        class="number5 position-absolute"
                        src="../../assets/views/educational_partnership/final.png"
                        alt=""
                        width="63px"
                        height="63px"
                    >
                    <img
                        class="number5__line position-absolute"
                        src="../../assets/views/educational_partnership/straight-line5.svg"
                        alt=""
                    >
                    <div class="number5__text number-text position-absolute">
                        {{ getStageDescription(5) }}
                    </div>

                    <img src="../../assets/views/educational_partnership/line.svg" alt="">
                </div>
            </div>

            <div class="col-12 col-xxl-5 d-flex flex-column ms-xxl-5 mt-5">
                <div class="educational-partnership__about-partnership">
                    <div class="about-partnership__title">{{ getEducationalPartnershipPageInfo('subtitle_1') }}</div>
                    <div class="about-opportunities__title mt-4">{{ getEducationalPartnershipPageInfo('subtitle_2') }}</div>
                    <div class="about-partnership__text mt-4">
                        {{ getEducationalPartnershipPageInfo('subtext_2') }}
                    </div>

                    <div class="about-opportunities__title mt-4">{{ getEducationalPartnershipPageInfo('subtitle_3') }}</div>
                    <div class="about-opportunities__text mt-3">
                        {{ getEducationalPartnershipPageInfo('subtext_3') }}
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Nav from "@/components/Nav/Nav";
import {onMounted} from "vue";
import routerMixin from "@/mixins/routerMixin";
import { useStore } from "vuex";
import {computed} from "vue";

export default {
    name: "EducationalPartnership",

    components: {
        Nav
    },

    setup() {
        const store = useStore()

        const { isMenuVisible, toggleMenu } = routerMixin();

        onMounted(async () => {
            if (isMenuVisible.value) {
                await toggleMenu()
            }

            document.body.scrollIntoView({ behavior: "smooth" });
        })

        store.dispatch('widgets/getEducationalPartnershipStages')
        const educationalPartnershipStages = computed(() => store.getters['widgets/educationalPartnershipStages'])

        // Текст для этапов схемы
        const getStageDescription = (order) => {
            if (educationalPartnershipStages.value) {
                return educationalPartnershipStages.value.items.find(item => item.order === order).description
            }

            return ''
        }

        // Статы
        store.dispatch('widgets/getEducationalPartnershipStats')
        const educationalPartnershipStats = computed(() => store.getters['widgets/educationalPartnershipStats'])

        const getStatNumber = (order) => {
            if (educationalPartnershipStages.value) {
                return educationalPartnershipStats.value?.items.find(item => item.order === order).number
            }

            return ''
        }

        const getStatDescription = (order) => {
            if (educationalPartnershipStages.value) {
                return educationalPartnershipStats.value?.items.find(item => item.order === order).description
            }

            return ''
        }

        store.dispatch('pages/getEducationalPartnershipPage')
        const educationalPartnershipPage = computed(() => store.getters['pages/educationalPartnershipPage'])

        const getEducationalPartnershipPageInfo = (key) => {
            if (educationalPartnershipPage.value) {
                return educationalPartnershipPage.value[key]
            }

            return ''
        }

        return {
            educationalPartnershipStats,
            getStageDescription,
            getEducationalPartnershipPageInfo,
            getStatNumber,
            getStatDescription
        }
    }
}
</script>
